const navList = [
  {
    name: 'Home',
    link: '/',
  },
  {
    name: 'Diensten',
    link: '/diensten',
  },
  {
    name: 'Realisaties',
    link: '/realisaties',
  },
  {
    name: 'Contact',
    link: '/contact',
  },
]

export default navList
